import React from 'react';
import PropTypes from 'prop-types';
import { Link, graphql } from 'gatsby';
import Moment from 'react-moment';
import { Layout, Wrapper, SliceZone, SEO, Header, ListingCard } from '../../components';
import website from '../../../config/website';
import { Hero, HeroInner, Headline, HeroBrightner, StyledWrapper, HeadWrapper, HeroThumb } from './Elements';
import Img from "gatsby-image";

const Secteur = ({ data: { prismicSecteur, posts }, location }) => {
  const { data } = prismicSecteur;

  return (
    <Layout customSEO>
      <SEO
        title={`${data.secteur_d_activite.text} | ${website.titleAlt}`}
        pathname={location.pathname}
        // desc={data.shortdesc.text}
        // banner={data.main.full.url}
        node={prismicSecteur}
        article
      />
      <Header light />
      {/*<Hero style={{ backgroundImage: `url(${data.main.full.url})` }}>*/}
      {/*  /!*<HeroBrightner />*!/*/}
      {/*  <HeroInner>*/}
      {/*    <HeadWrapper>*/}
      {/*    <Headline dangerouslySetInnerHTML={{ __html: data.secteur_d_activite.html }} />*/}
      {/*    /!*<div dangerouslySetInnerHTML={{ __html: data.shortdesc.html }} />*!/*/}
      {/*  </HeadWrapper>*/}
      {/*  </HeroInner>*/}
      {/*</Hero>*/}

        <SliceZone allSlices={data.body} />
      <StyledWrapper style={{ paddingTop: '2rem', paddingBottom: '2rem'}}>
        <Link className="button" to="/contact">Nous contacter</Link>
        {/*<h3 style={{ marginTop: '4rem' }}>Nos autres offres</h3>*/}
        {/*<ListingCard posts={posts.edges} />*/}
      </StyledWrapper>
    </Layout>
  );
};

export default Secteur;

Secteur.propTypes = {
  data: PropTypes.shape({
    prismicSecteur: PropTypes.object.isRequired,
    posts: PropTypes.object.isRequired,
  }).isRequired,
  location: PropTypes.object.isRequired,
};

// The typenames come from the slice names
// If this doesn't work for you query for __typename in body {} and GraphiQL will show them to you

export const pageQuery = graphql`
  query DomaineBySlug($uid: String!) {
      prismicSecteur(uid: { eq: $uid }) {
      uid
      data {
          secteur_d_activite {
              html
              text
          }
#        shortdesc {
#          text
#          html
#        }
#          full {
#            localFile {
#              childImageSharp {
#                resize(width: 1200, height: 675, quality: 80) {
#                  src
#                }
#              }
#            }
#            url
#          }

        body {
            ... on PrismicSecteurBodyHero {
                slice_type
                id
                primary {
                    maintitle {
                        html
                        text
                    }
                    subtitle {
                        html
                        text
                    }
                    introduction {
                        html
                        text
                    }
                    bgimg {
                        url
                        localFile {
                            childImageSharp {
                                fluid(maxWidth: 1960, quality: 90) {
                                    ...GatsbyImageSharpFluid_withWebp
                                }
                            }
                        }
                    }
                    illustration {
                        url
                        localFile {
                            childImageSharp {
                                fluid(maxWidth: 560, quality: 90) {
                                    ...GatsbyImageSharpFluid_withWebp
                                }
                            }
                        }
                    }
                    bgcolor
                    orientation
                    theme
                }
            }
          ... on PrismicSecteurBodyTextpage {
            slice_type
            id
            primary {
              title1{
                text
                html  
              }  
              text {
                html
                text
              }
            }
          }
          ... on PrismicSecteurBodyDomainesDIntervention {
              id
              primary {
                  title {
                      html
                      text
                  }
                  bgcolor
                  display
              }
              slice_type
              items {
                  domaine {
                      document {
                          uid
                          data {
                              title {
                                  html
                                  text
                              }
                              image {
                                  localFile {
                                      childImageSharp {
                                          fluid(maxWidth: 200, quality: 90) {
                                              ...GatsbyImageSharpFluid_withWebp
                                          }
                                      }
                                  }
                              }
                              description_du_domaine {
                                  html
                                  text
                              }
                          }
                      }
                  }
              }
          }
          ... on PrismicSecteurBodyMoyenAccompagnement {
              id
              slice_type
              primary {
                  title {
                      text
                      html
                  }
                  bgcolor
              }
              items {
                  domaine {
                      document {
                          data {
                              image {
                                  localFile {
                                      childImageSharp {
                                          fluid(maxWidth: 200, quality: 90) {
                                              ...GatsbyImageSharpFluid_withWebp
                                          }
                                      }
                                  }
                                  alt
                              }
                              title {
                                  html
                                  text
                              }
                          }
                      }
                  }
              }
          }
          ... on PrismicSecteurBodySecteursDActivite {
              id
              primary {
                  title {
                      html
                      text
                  }
#                  bgcolor
              }
              slice_type
              items {
                  area {
                      document {
                          data {
                              thumb {
                                localFile {
                                    childImageSharp {
                                        fluid(maxWidth: 200, quality: 90) {
                                            ...GatsbyImageSharpFluid_withWebp
                                        }
                                    }
                                }
                                alt
                              }
                              secteur_d_activite {
                                  text
                                  html
                              }
                          }
                          uid
                      }
                  }
              }
          }
            ... on PrismicSecteurBodySelectionDArticles {
                id
                items {
                    articles {
                        document {
                            uid
                            data {
                                date
                                main {
                                    full {
                                        localFile {
                                            childImageSharp {
                                                fluid(maxWidth: 400, quality: 90) {
                                                    ...GatsbyImageSharpFluid_withWebp
                                                }
                                            }
                                        }
                                    }
                                }
                                title {
                                    html
                                    text
                                }
                                shortdesc {
                                    html
                                    text
                                }
                                categorie {
                                    document {
                                        uid
                                        data {
                                            name
                                            color
                                        }
                                    }
                                }
                                authors {
                                    author {
                                        id
                                        uid
                                        document {
                                            data {
                                                name
                                                image {
                                                    url
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                primary {
                    title {
                        html
                        text
                    }
                    bgcolor
                }
                slice_type
            }
#          ... on PrismicSecteurBodyQuote {
#            slice_type
#            id
#            primary {
#              quote {
#                html
#                text
#              }
#              author
#            }
#          }
#          ... on PrismicSecteurBodyCodeBlock {
#            slice_type
#            id
#            primary {
#              code_block {
#                html
#              }
#            }
#          }
#          ... on PrismicSecteurBodyVideo {
#            slice_type
#            id
#            primary {
#              video {
#                html
#              }
#            }
#          }
#          ... on PrismicSecteurBodyListeDeProfiles {
#              id
#              items {
#                  person_selected {
#                      uid
#                      document {
#                          data {
#                              name
#                              surname
#                              description {
#                                  html
#                                  text
#                              }
#                              image {
#                                  thumb {
#                                      localFile {
#                                          childImageSharp {
#                                              fluid(maxWidth: 400, quality: 90) {
#                                                  ...GatsbyImageSharpFluid_withWebp
#                                              }
#                                          }
#                                      }
#                                  }
#                              }
#                          }
#                          uid
#                      }
#                  }
#              }
#              slice_type
#              primary {
#                  selectedtext {
#                      html
#                      text
#                  }
#              }
#          }
#          ... on PrismicSecteurBodyListeImageTexte {
#              id
#              slice_type
#              items {
#                  image {
#                      mini {
#                          localFile {
#                              childImageSharp {
#                                  fluid(maxWidth: 200, quality: 90) {
#                                      ...GatsbyImageSharpFluid_withWebp
#                                  }
#                              }
#                          }
#                      }
#                  }
#                  text {
#                      html
#                  }
#                  title {
#                      html
#                  }
#              }
#          }
#          ... on PrismicSecteurBodyImage {
#            slice_type
#            id
#            primary {
#              image {
#                localFile {
#                  childImageSharp {
#                    fluid(maxWidth: 800, quality: 90) {
#                      ...GatsbyImageSharpFluid_withWebp
#                    }
#                  }
#                }
#              }
#            }
#          }
        }
      }
    }
    posts: allPrismicPost(limit: 3, sort: { fields: [data___date], order: DESC }, filter: { uid: { ne: $uid } }) {
      edges {
        node {
          uid
          first_publication_date
          last_publication_date
          data {
            title {
              text
            }
            shortdesc {
              text
            }
            main {
              full {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 400, quality: 90) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
            date(formatString: "DD.MM.YYYY")
            categorie {
              document {
                uid
                data {
                  name
                  color
                }
              }
            }
            authors {
              author {
                id
                uid
                document {
                  data {
                    name
                    image {
                      url
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
